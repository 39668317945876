import logger from 'logger'
import cacheStorage from 'cache-storage'


type OpenModalOpts = { [key: string]: any }
export type OpenedModals = string[]
export type WaitingModals = { [key: string]: OpenModalOpts }
type Handler = (isVisible: boolean, props?: any) => void
type Subscribers = { [key: string]: Handler }


if (__CLIENT__) {
  window.openedModals = window.openedModals || []
  window.waitingModals = window.waitingModals || {}
}

const subscribers: Subscribers = __CLIENT__ ? window.modalSubscribers || {} : {}

if (__CLIENT__) {
  if (!window.modalSubscribers) {
    window.modalSubscribers = subscribers
  }
}


const subscribe = (modalName: string, handler: Handler) => {
  if (subscribers[modalName]) {
    console.error(`Multiple modals with same name: ${modalName}`)
  }

  subscribers[modalName] = handler

  // console.log('Subscribe for', modalName, subscribers)

  if (modalName in window.waitingModals) {
    // if there is an unopened modal, open it
    openModal(modalName, window.waitingModals[modalName])
  }
}

const unsubscribe = (modalName: string) => {
  delete subscribers[modalName]
}

const openModal = <TProps extends {}>(name: string, props?: TProps & OpenModalOpts) => {
  // console.log('Open modal', name, subscribers)
  if (typeof subscribers[name] === 'function') {
    logger.info(`Open "${name}" modal`)

    window.openedModals.push(name)
    subscribers[name](true, props)
    delete window.waitingModals[name]

    return
  }

  // sometimes openModal can be called before subscribe,
  // so we need to save them to show later
  window.waitingModals[name] = props
}

const closeModal = (name?: string): void => {
  const modalName = name || window.openedModals[window.openedModals.length - 1]

  if (typeof subscribers[modalName] === 'function') {
    logger.info(`Close "${name}" modal`)

    window.openedModals = window.openedModals.filter((v) => v !== modalName)
    subscribers[modalName](false)
  }

  delete window.waitingModals[modalName]
}

const closeAllModals = (): void => {
  window.openedModals.forEach((name) => {
    if (typeof subscribers[name] === 'function') {
      subscribers[name](false)
    }
  })

  window.openedModals = []
  window.waitingModals = {}
}

const getOpenedModals = (): OpenedModals => window.openedModals


export {
  subscribe,
  unsubscribe,
  openModal,
  closeModal,
  closeAllModals,
  getOpenedModals,
}
